import React, { Suspense, Fragment, lazy } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import LoadingScreen from 'src/components/LoadingScreen';
import AuthGuard from 'src/components/AuthGuard';
import GuestGuard from './components/GuestGuard';
import DocumentationLayout from './layouts/DocumentationLayout';

type Routes = {
  exact?: boolean;
  path?: string | string[];
  guard?: any;
  layout?: any;
  component?: any;
  routes?: Routes;
}[];

export const renderRoutes = (routes: Routes = []): JSX.Element => (
  <Suspense fallback={<LoadingScreen />}>
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;

        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={(props) => (
              <Guard>
                <Layout>{route.routes ? renderRoutes(route.routes) : <Component {...props} />}</Layout>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
);

const routes: Routes = [
  {
    exact: true,
    path: '/404',
    component: lazy(() => import('src/views/errors/NotFoundView')),
  },
  {
    exact: true,
    path: '/conecte-sus',
    component: lazy(() => import('src/views/conecteSUS/ConecteSUS')),
  },
  {
    exact: true,
    path: '/login',
    guard: GuestGuard,
    component: lazy(() => import('src/views/auth/AuthView')),
  },
  {
    exact: true,
    path: '/register',
    guard: GuestGuard,
    component: lazy(() => import('src/views/auth/AuthView')),
  },
  {
    path: '/documentation',
    guard: AuthGuard,
    layout: DocumentationLayout,
    routes: [
      {
        exact: true,
        path: '/documentation',
        component: lazy(() => import('src/views/account/AccountView')),
      },
      {
        exact: true,
        path: '/documentation/protocols/noronha',
        component: lazy(() => import('src/views/documentation/ProtocolsNoronha')),
      },
      {
        exact: true,
        path: '/documentation/protocols/pernambuco',
        component: lazy(() => import('src/views/documentation/ProtocolsPernambuco')),
      },
      {
        exact: true,
        path: '/documentation/protocols/sao_paulo',
        component: lazy(() => import('src/views/documentation/ProtocolsSaoPaulo')),
      },
      {
        exact: true,
        path: '/documentation/suggested_answers/redo',
        component: lazy(() => import('src/views/documentation/SuggestedAnswersRedo')),
      },
      {
        exact: true,
        path: '/documentation/suggested_answers/inconsistent',
        component: lazy(() => import('src/views/documentation/SuggestedAnswersInconsistent')),
      },
      {
        exact: true,
        path: '/documentation/references',
        component: lazy(() => import('src/views/documentation/References')),
      },
      {
        component: () => <Redirect to="/404" />,
      },
    ],
  },

  {
    path: '/app',
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/app/support',
        component: lazy(() => import('src/views/support')),
      },
      {
        exact: true,
        path: '/app/account',
        component: lazy(() => import('src/views/account/AccountView')),
      },
      {
        exact: false,
        path: '/app/management/users/:userId',
        component: lazy(() => import('src/views/management/UserDetails')),
      },
      {
        exact: false,
        path: '/app/management/user-sick-notes/:enterpriseId/:status/:userId',
        component: lazy(() => import('src/views/management/UserSickNotes')),
      },
      {
        exact: true,
        path: '/app/management/protocol/:protocolId',
        component: lazy(() => import('src/views/management/PasseVerdeByProtocol')),
      },
      {
        exact: true,
        path: '/app/management/enterprise/:enterpriseId',
        component: lazy(() => import('src/views/management/SickNotesByEnterprise')),
      },
      {
        exact: true,
        path: '/app/management/enterprise/:enterpriseId/:status',
        component: lazy(() => import('src/views/management/SickNotesByEnterprise/ListUsersStatusPage')),
      },
      {
        exact: true,
        path: '/app/management/vcr',
        component: lazy(() => import('src/views/management/VaccinationsCard')),
      },
      {
        exact: true,
        path: '/app/management/vcr/:status',
        component: lazy(() => import('src/views/management/VaccinationsCard/ListUsersStatusPage')),
      },
      {
        exact: false,
        path: '/app/management/vcr/:status/:userId',
        component: lazy(() => import('src/views/management/UserVaccinationsCard')),
      },
      {
        exact: true,
        path: '/app/management/enterprise-operators',
        component: lazy(() => import('src/views/management/EnterpriseOperator/SplittedPanel')),
      },
      {
        exact: true,
        path: '/app/management/enterprise-operators/:status',
        component: lazy(() => import('src/views/management/EnterpriseOperator/ListByStatus')),
      },
      {
        exact: true,
        path: '/app/management/enterprise-operator/:enterpriseOperatorId',
        component: lazy(() => import('src/views/management/EnterpriseOperator/Details')),
      },
      {
        exact: true,
        path: '/app/management/contact-tickets/:subject/',
        component: lazy(() => import('src/views/management/ContactTickets/SplittedPanel')),
      },
      {
        exact: true,
        path: '/app/management/contact-tickets/:subject/:status',
        component: lazy(() => import('src/views/management/ContactTickets/ListByStatus')),
      },
      {
        exact: true,
        path: '/app/management/contact-ticket/:contactTicketId',
        component: lazy(() => import('src/views/management/ContactTickets/Details')),
      },
      {
        exact: false,
        path: '/app/management/add/contact-ticket/:type',
        component: lazy(() => import('src/views/management/ContactTickets/Add')),
      },
      {
        exact: true,
        path: '/app/management/hcps',
        component: lazy(() => import('src/views/management/HCP/SplittedPanel')),
      },
      {
        exact: true,
        path: '/app/management/hcps/:status',
        component: lazy(() => import('src/views/management/HCP/ListByStatus')),
      },
      {
        exact: true,
        path: '/app/management/hcp/:hcpId',
        component: lazy(() => import('src/views/management/HCP/Details')),
      },
      {
        exact: true,
        path: '/app/management/protocol/:protocolId/:status',
        component: lazy(() => import('src/views/management/PasseVerdeByProtocol/ListUsersStatusPage')),
      },
      {
        exact: true,
        path: '/app/management/users-list',
        component: lazy(() => import('src/views/management/PasseVerdeListNoronha')),
      },
      {
        exact: false,
        path: '/app/management/users-list/:status',
        component: lazy(() => import('src/views/management/PasseVerdeListNoronha/ListUsersStatusPage')),
      },
      {
        exact: true,
        path: '/app/management/search',
        component: lazy(() => import('src/views/management/Search')),
      },
      {
        exact: true,
        path: '/app/management/show-list',
        component: lazy(() => import('src/views/management/PasseVerdeEventosPernambuco')),
      },
      {
        exact: false,
        path: '/app/management/show-list/:status',
        component: lazy(() => import('src/views/management/PasseVerdeEventosPernambuco/ListUsersStatusPage')),
      },
      {
        exact: false,
        path: '/app/management/edit-user-profile-data/:userId',
        component: lazy(() => import('src/views/management/EditUserProfileData')),
      },
      {
        exact: false,
        path: '/app/management/edit-user-vaccination-data/:userId',
        component: lazy(() => import('src/views/management/EditUserVaccinationData')),
      },
      {
        exact: true,
        path: '/app/add/hcb',
        component: lazy(() => import('src/views/add/HCB')),
      },
      {
        exact: true,
        path: '/app/add/plan',
        component: lazy(() => import('src/views/add/Plan')),
      },
      {
        exact: true,
        path: '/app/add/enterprise',
        component: lazy(() => import('src/views/add/Enterprise')),
      },
      {
        exact: true,
        path: '/app/add/enterprise/create',
        component: lazy(() => import('src/views/add/Enterprise/EnterpriseCreate')),
      },
      {
        exact: true,
        path: '/app/add/enterprise/edit/:enterpriseId',
        component: lazy(() => import('src/views/add/Enterprise/EnterpriseEdit')),
      },
      {
        exact: true,
        path: '/app/settings/add-events',
        component: lazy(() => import('src/views/settings/AddEvents')),
      },
      {
        exact: true,
        path: '/app/settings/add-organizer',
        component: lazy(() => import('src/views/settings/AddOrganizer')),
      },
      {
        exact: true,
        path: '/app/settings/work-schedule',
        component: lazy(() => import('src/views/settings/WorkSchedule')),
      },
      {
        exact: true,
        path: '/app/metrics/commercial',
        component: lazy(() => import('src/views/metrics/CommercialMetrics')),
      },
      {
        exact: true,
        path: '/app/metrics/operational',
        component: lazy(() => import('src/views/metrics/OperationalMetrics')),
      },
      {
        component: () => <Redirect to="/404" />,
      },
    ],
  },
  {
    exact: true,
    path: '*',
    component: () => <Redirect to="/app/account" />,
  },
];

export default routes;
